import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import "../styles/news.scss"
import "../styles/caseStudy.scss"
import NewsDetails from "../components/NewsDetails"
import CaseStudeiesTopSection from '../components/CaseStudeiesTopSection'

import VisiomateD365Pdf from '../files/Visiomate_D365.pdf';
import VisiomateMobilePdf from '../files/Visiomate_Mobile_Portfolio.pdf';
import VisiomateOdooPdf from '../files/Visiomate_Odoo.pdf';
import VisiomateProfilePdf from '../files/Visiomate_Profile.pdf';
import VisiomateViztosPdf from '../files/Visiomate_Viztos_POS.pdf';

const WhitepapereBooks = ({ data }) => {
  return (
    <div className="CaseStudeies news">
      <Layout pageTitle={"Visiomate - E-Books"}
      descrip = {"Gain valuable insights with our collection of white papers and e-books. Explore industry trends, best practices, and expert analysis. Download now!"}>
        <CaseStudeiesTopSection
          path='whitepaperebooks'
          headerData={{
            title: "The distribution utility is integrated with Microsoft Dynamics D365. The purpose of this utility is to provide users ability to create transfer order in bulk from outside the Microsoft Dynamics D365.",
            peragraph: ""
          }} data={data} />
        <NewsDetails

          data={{
            title: "Know Us Better",
            News: [
              {
                heading: "Visiomate Dynamics 365 Services Brochure",
                description:
                  "Unlock Dynamics 365's potential with Visiomate, empowering efficient processes and tailored solutions for your business needs.",
                button: 'Download',
                Link:VisiomateD365Pdf,
                state:'Document'
              },
              {
                heading: "Visiomate Odoo Services Brochure",
                description:
                  "Maximize operational efficiency and effectiveness of Odoo with Visiomate for enhanced business performance and streamlined workflows.",
                button: 'Download',
                Link:VisiomateOdooPdf,
                state:'Document'
              },
              {
                heading: "Visiomate Mobile Development Services Brochure",
                description:
                  "Turning Your Unique Vision into Innovative, Exceptional, and User-Centric Mobile Experiences Tailored to Your Business Needs.",
                button: 'Download',
                Link:VisiomateMobilePdf,
                state:'Document'
              },
              {
                heading: "Visiomate Viztos Brochure",
                description:
                  "Viztos -  is a custom made POS based on Odoo that eliminates the common limitations in any other POS software businesses face.",
                button: 'Download',
                Link:VisiomateViztosPdf,
                state:'Document'
              },
              {
                heading: "Visiomate Profile",
                description:
                  "We architect pathways to success, ensuringadaptability and scalability for our clients sustained growth in an ever-evolving digitallandscape.",
                button: 'Download',
                Link:VisiomateProfilePdf,
                state:'Document'
              },
              

            ],
          }} dataforImages={data} />
      </Layout>
    </div>
  )
}

export default WhitepapereBooks
export const query = graphql`
  query WhitepapereBooksImages {
    VMLogoBlack: file(relativePath: { eq: "logo_black_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoWhite: file(relativePath: { eq: "logo_white_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    newsHeaderImg: file(relativePath: { eq: "DistributionD365/dist_top_header.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    newsCard1: file(relativePath: { eq: "news/Document_brochure.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    newsCard2: file(relativePath: { eq: "news/Document_brochure.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    newsCard3: file(relativePath: { eq: "news/Document_brochure.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    newsCard4: file(relativePath: { eq: "news/Document_brochure.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    newsCard5: file(relativePath: { eq: "news/Document_brochure.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    newsCard6: file(relativePath: { eq: "news/Document_brochure.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;


